'use strict';
import axios           from 'axios';
import {isPlainObject} from 'lodash/lang';
import {mapValues}     from 'lodash/object';
import {padStart}      from 'lodash/string';

axios.defaults.headers.common['X-Requested-With']='XMLHttpRequest';
axios.defaults.baseURL=`${window.location.origin}/${import.meta.env.VITE_VUE_PREFIX}`;
axios.defaults.headers['X-TIMEZONE']=Date ().match (/GMT[+-]\d{2}/)[0];
axios.defaults.withCredentials=true;

window.decodeList=(list)=>
	{
		if (!list) return null;
		if (Array.isArray (list)) return list.map (item=>decodeListItem (item));
		if (isPlainObject (list)) return mapValues (list,decodeListItem);
		return null;
		
		function decodeListItem (item)
			{
				let flat=!isPlainObject (item);
				let decoded=flat?{}:item;
				decoded.title=flat?item:item.title ?? '';
				decoded.icon=flat?'':item.icon ?? '';
				decoded.type=flat?null:item.type ?? null;
				return decoded;
			}
	};

window.hms=(time)=>
	{
		time=Math.round (time);
		return padStart (Math.floor (time/3600),2,0)+':'+padStart (Math.floor (time/60)%60,2,0)+':'+padStart (time%60,2,0);
	};

window.hm=time=>padStart (Math.floor (time/3600),2,0)+':'+padStart (Math.floor (time/60)%60,2,0);
window.ms=time=>padStart (Math.floor (time/60),2,0)+':'+padStart (Math.round (time)%60,2,0);
window.sec=time=>padStart (Math.round (time)%60,2,0);
window.dateToLocaleString=date=>date?(new Date (date)).toLocaleString ():undefined;
Math.between=(number,min,max)=>number>=min && number<=max;
Math.clamp=(number,min,max)=>Math.min (max,Math.max (min,number));
